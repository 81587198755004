declare global {
  interface Window {
    RSIStore: {
      widgets: string[];
      domainUrl: string;
      load: () => any;
      apps: any;
    };
  }
}

const RSIStore = {
  domainUrl: process.env.PLATFORM_API_URI,
  apps: undefined,
  origin: window.location !== window.parent.location ? window.parent.location.origin : document.location.origin,
  load: async (url = origin) => {
    if (url) {
      __webpack_public_path__ = url + process.env.REPO_BASE_URI + '/';
      RSIStore.domainUrl = url;
    }
    await import(/* webpackPrefetch: true, webpackChunkName: "polyfills" */ './polyfills');
    return await import(/* webpackPrefetch: true, webpackChunkName: "index" */ './index').then((d) => {
      RSIStore.apps = d.default;
      return d;
    });
  },
};
export default RSIStore;
