(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("faker"));
	else if(typeof define === 'function' && define.amd)
		define(["faker"], factory);
	else if(typeof exports === 'object')
		exports["RSIStore"] = factory(require("faker"));
	else
		root["RSIStore"] = factory(root["faker"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__) {
return 